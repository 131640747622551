<template>
  <ERow no-gutters>
    <!--SNAPSHOT-->
    <ECol cols="9" md="9" sm="12">
      <v-card>
        <v-card-title class="pl-4 subtitle-1">
          <ERow justify="between" align="center" class="px-4"> Snapshots </ERow>
        </v-card-title>
        <v-card-text>
          <RecordingsTimePicker
            v-if="selectedDay"
            v-model="datetime"
            :selectedDay="selectedDay"
            :disable-play-button="true"
            @image-load="onSnapshotLoad"
          >
            <ERow
              v-if="isProcessing"
              class="fill-height ma-0 img-loader"
              align="center"
              justify="center"
            >
              <EvercamLoadingAnimation :verify-theme-mode="false" />
            </ERow>
            <portal-target
              v-else
              name="evercam-boxes-container"
              class="h-100 w-100"
            ></portal-target>
          </RecordingsTimePicker>
        </v-card-text>
      </v-card>
    </ECol>

    <ECol
      cols="3"
      md="3"
      sm="12"
      class="pr-4"
      :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : ''"
    >
      <RecordingsDatePicker v-model="selectedDay" />
      <v-tabs
        v-model="selectedBrainToolTab"
        class="camera-details__tabs custom-tabs"
        active-class="blue lighten-5"
        @change="onTabChange"
      >
        <v-tab
          v-for="tab in brainToolTabs"
          :key="tab.value"
          class="mx-0 px-6"
          :disabled="isTabDisabled(tab.value)"
          >{{ tab.name }}</v-tab
        >
        <v-tab-item v-for="tab in brainToolTabs" :key="tab.value">
          <AskChatGpt
            v-if="showAskChatGptTab"
            :key="brainToolTabs[0].value"
            :camera-exid="cameraDialogStore.camera.exid"
            :timestamp="formattedTimestamp"
            with-prompt
          />
          <EvercamObjectDetection
            v-if="showObjectDetectionTab"
            :cameraId="cameraDialogStore.camera.exid"
            :image="image"
            :timestamp="formattedTimestamp"
            :image-dimension="imageDimension"
            :provider="provider"
            trigger-on-image-change
            :sidebarHeight="'400px'"
            @is-processing="(flag) => (isProcessing = flag)"
          />
          <Segmentation
            v-if="showSegmentation"
            :key="key"
            :camera-exid="cameraDialogStore.camera.exid"
            :img-element="cameraDialogStore.imageElement"
            :img-src="image"
            :image-dimensions="imageDimension"
            :timestamp="formattedTimestamp"
            @is-processing="(flag) => onResize()"
          />
          <DepthAnalysis
            v-if="showDepthAnalysis"
            :key="key"
            :camera-exid="cameraDialogStore.camera.exid"
            :img-element="cameraDialogStore.imageElement"
            :timestamp="formattedTimestamp"
          />
        </v-tab-item>
      </v-tabs>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import Vue from "vue"
import RecordingsDatePicker from "@/components/cameraTabs/RecordingsDatePicker.vue"
import RecordingsTimePicker from "@/components/cameraTabs/RecordingsTimePicker.vue"
import AskChatGpt from "@evercam/shared/components/AskChatGpt.vue"
import EvercamObjectDetection from "@evercam/shared/components/EvercamObjectDetection.vue"
import Segmentation from "@evercam/shared/components/Segmentation.vue"
import DepthAnalysis from "@evercam/shared/components/DepthAnalysis.vue"
import { AiApi } from "@evercam/shared/api/aiApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation.vue"
import { BrainToolTabs } from "@/components/constants"
import { AxiosResponse } from "axios"
export default Vue.extend({
  name: "BrainTool",
  components: {
    RecordingsDatePicker,
    RecordingsTimePicker,
    EvercamObjectDetection,
    AskChatGpt,
    EvercamLoadingAnimation,
    Segmentation,
    DepthAnalysis,
  },
  data() {
    return {
      datetime: null,
      formattedTimestamp: null,
      image: null,
      imageDimension: null,
      selectedDay: null,
      isPlaying: false,
      isProcessing: false,
      imageElement: null as HTMLImageElement | null,
      selectedBrainToolTab: 0,
      key: 0,
      brainToolTabs: [
        { name: "Ask ChatGPT", value: BrainToolTabs.CHATGPT },
        { name: "Detections", value: BrainToolTabs.DETECTIONS },
        { name: "Segmentation", value: BrainToolTabs.SEGMENTATION },
        { name: "Depth Analysis", value: BrainToolTabs.DEPTH_ANALYSIS },
      ],
      mainHeight: 0,
      mainWidth: 0,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    showAskChatGptTab(): boolean {
      return (
        this.brainToolTabs[this.selectedBrainToolTab].value ===
        BrainToolTabs.CHATGPT
      )
    },
    showObjectDetectionTab(): boolean {
      return (
        this.brainToolTabs[this.selectedBrainToolTab].value ===
        BrainToolTabs.DETECTIONS
      )
    },
    showSegmentation(): boolean {
      return (
        this.brainToolTabs[this.selectedBrainToolTab].value ===
        BrainToolTabs.SEGMENTATION
      )
    },
    showDepthAnalysis(): boolean {
      return (
        this.brainToolTabs[this.selectedBrainToolTab].value ===
        BrainToolTabs.DEPTH_ANALYSIS
      )
    },
    cameraTimezone(): string {
      return this.cameraDialogStore.camera?.timezone || "Europe/Dublin"
    },
    provider(): (
      cameraId: any,
      params?: {}
    ) => Promise<AxiosResponse<any, any>> {
      return AiApi.brainTool.getObjectDetectionsResults
    },
  },
  watch: {
    datetime() {
      this.formattedTimestamp = this.$moment(this.datetime)
        .tz(this.cameraTimezone)
        .format("YYYY-MM-DD HH:mm:ss")
      this.updateUrlState()
    },
  },
  created() {
    const { timestamp } = this.getSubQueryParams()
    this.selectedDay = this.getFormattedDate(timestamp || new Date())
    this.datetime = this.getFormattedDate(
      this.$route.query.timestamp || new Date()
    )
  },
  methods: {
    isTabDisabled(tabValue) {
      return tabValue === BrainToolTabs.DEPTH_ANALYSIS
    },
    getFormattedDate(date) {
      return this.$moment
        .tz(date, this.cameraTimezone)
        .format("YYYY-MM-DDTHH:mm:ss")
    },
    updateUrlState() {
      this.updateSubQuery({
        timestamp: this.formattedTimestamp,
      })
    },
    onResize() {
      this.$setTimeout(() => {
        if (
          this.imageElement?.offsetHeight !== this.imageDimension?.height ||
          this.imageElement?.offsetWidth !== this.imageDimension?.width
        ) {
          this.imageDimension = {
            height: this.imageElement?.offsetHeight,
            width: this.imageElement?.offsetWidth,
          }
        }
      }, 500)
    },
    onSnapshotLoad(image) {
      this.image = image.src
      this.imageElement = image
      this.onResize()
    },
    onTabChange(tab) {
      this.selectedBrainToolTab = tab
      this.key = tab.value
    },
    showTab(tabValue, tabName) {
      return tabValue === BrainToolTabs[tabName]
    },
  },
})
</script>

<style scoped lang="scss">
.v-tab {
  font-size: 0.75rem !important;
}
</style>
